import { css } from 'styled-components';

import {
  FontSize,
  FontFamily,
  Components,
  TextTransformOptions,
} from '@powdr/constants';

import RefrigeratorDeluxeExtraBoldWoff from '../assets/fonts/Refrigerator-Deluxe-Extra-Bold.woff';

/* const ComponentFontAdjustments = {
  [ComponentGroups.HERO]: {
    title: '90px',
    'title-large-bp': '90px',
    'title-smaller-bp': '55px',
    copy: '26px',
    'copy-larger-bp': '30px',
    'copy-large-bp': '28px',
    'copy-smaller-bp': '22px',
  },
}; */

// For some reason, this font just won't load properly via the CSS version,
// have tried TONS of different fixes
// so this will be here for now until the font is replaced or we figure out a fix
const FontFaces = css`
  @font-face {
    font-family: 'Refrigerator Deluxe - Extra Bold';
    src:url(${RefrigeratorDeluxeExtraBoldWoff}) format('woff');
    font-display: swap;
  }
`;

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"Gotham Book"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"Refrigerator Deluxe - Extra Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.LRG30,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"Gotham Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
};

const fontOverrides = {
  [Components.CONTENT_BLOCK_HERO]: {
    header: {
      size: '90px',
    },
    copy: {
      size: FontSize.MED40,
    },
  },
};

export const fontTheme = {
  fonts,
  fontOverrides,
  FontSize,
  FontFaces,
};
