module.exports = {
  gatsbyConfig: {
    property: 'coppercolorado',
    siteName: 'Copper Mountain',
    siteUrl: 'https://www.coppercolorado.com',
    reCaptchaDBPropertyName: 'Copper',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    osano: {
      customerId: 'AzqLgKU480gvIIMEX',
      configId: '26417912-36c6-425d-b955-e218b031792c',
    },
    color: '#18D5EE',
    facebook: 'CopperMtnTest',
    instagram: 'coppermtn',
    twitter: 'CopperMtn',
    dor: {
      temperature: {
        name: '',
        api: 'weather',
      },
      liveWeather: false,
      weatherApi: 'opensnow',
      attributionRequired: true,
    },
    dynamicTypes: [
      'event',
      'pressRelease',
      'blog',
    ],
    ignore404Routes: [
      '/things-to-do/events/november-events',
      '/things-to-do/events/december-events',
      '/things-to-do/events/january-events',
      '/things-to-do/events/february-events',
      '/things-to-do/events/march-events',
      '/things-to-do/events/april-events',
      '/culture/blog/all-blogs',
      '/press-room/press-releases/latest-press-releases',
    ],
  },
  // TODO: Good way of managing API keys per site, maybe use for formbucket and mailchimp?
  contentfulConfig: {
    spaceId: '38vc2unmqsq5',
    accessToken: 'D2b0ZG2uKbPvWdW38GS0y3_hQN9AxH-iJnKijjoI0cA',
  },
};
